import StatusEvent from '@/models/StatusEvent';

export default class StatusEventRepository {
    public static getAll() {
        return StatusEvent.all();
    }

    public static getById(id: string) {
        return StatusEvent.query()
            .whereId(id)
            .withAll()
            .first();
    }
}
