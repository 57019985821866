
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ChatRichTextEditor from '@/components/views/ChatView/ChatRichTextEditor.vue';
import NextStepTypeRepository from '@/repositories/NextStepTypeRepository';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import ReminderOptionRepository from '@/repositories/ReminderOptionRepository';
import UpgradedNextStep from '@/models/interfaces/UpgradedNextStep';
import ReminderOption from '@/models/ReminderOption';
import NextStepType from '@/models/NextStepType';

export interface ActivityFormData {
    activityType: string;
    name: string;
    date: string;
    reminderOption: string;
    responsiblePersonType: string;
    note: string;
}

@Component({
    name: 'ActivityForm',
    components: {
        ChatRichTextEditor,
    },
})
export default class ActivityForm extends Vue {
    @Prop({ default: null }) private activity!: UpgradedNextStep | null;

    private localActivityType = '';
    private localName = '';
    private localResponsiblePersonType = '';
    private localNote = '';
    private userTypes: object[] = [
        { name: 'CREATOR', id: 'CREATOR' },
        { name: 'COMMERCIALIST', id: 'COMMERCIALIST' },
    ];

    private get activityTypes() {
        return NextStepTypeRepository.getAll();
    }

    private get activeUsers() {
        return [...this.userTypes, ...CMSUserRepository.getAll()];
    }

    @Watch('activity', {
        immediate: true,
    })
    private onActivityChange(activity: UpgradedNextStep) {
        if (this.activity) {
            this.localActivityType = this.activity?.nextStepType ?? this.activity?.activityType ?? '';
            this.localName = this.activity?.name ?? '';
            this.localResponsiblePersonType = this.activity?.responsiblePersonType ?? '';
            this.localNote = this.activity?.note ?? '';
        }
    }

    private onUpdateContent(content: string) {
        this.localNote = content;
        this.onInput();
    }

    private onInput() {
        this.$emit('change', {
            activityType: this.localActivityType,
            name: this.localName,
            responsiblePersonType: this.localResponsiblePersonType,
            note: this.localNote,
        });
    }

    private async created() {
        Promise.allSettled([NextStepType.getAll(), ReminderOption.getAll()]);
    }
}
