var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"automatic-events-wrapper"},[_c('div',{staticClass:"statuses__top-bar"},[_c('div',{staticClass:"automatic-events__span-wrapper"},[_c('span',{class:{ 'is-active': _vm.activeStatus === 'ARCHIVED' },on:{"click":function($event){_vm.activeStatus = 'ARCHIVED'}}},[_vm._v(" "+_vm._s(_vm.$t('Archived')))]),_c('div',{staticClass:"divider"}),_c('span',{class:{ 'is-active': _vm.activeStatus === 'ACTIVE' },on:{"click":function($event){_vm.activeStatus = 'ACTIVE'}}},[_vm._v(_vm._s(_vm.$t('Active')))])]),_c('div',{staticClass:"automatic-events__button-wrapper"},[_c('a-button',{staticClass:"statuses__create-button",attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t('Add New')))]),_c('a-button',{staticClass:"statuses__delete-button",attrs:{"type":"danger"},on:{"click":_vm.confirmDelete}},[_vm._v(_vm._s(_vm.$t('Delete')))])],1)]),_c('div',{staticClass:"automatic-events-content"},[_c('div',{staticClass:"search-bar"},[_c('a-input-search',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Search statuses data..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{staticClass:"statuses-table-wrapper"},[(_vm.statuses)?_c('a-table',{attrs:{"bordered":"","columns":_vm.columns,"rowKey":(record) => record.key,"data-source":_vm.filteredStatuses || _vm.statuses,"pagination":{ pageSize: 8 },"rowClassName":() => 'events__table-row',"row-selection":{
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange,
                },"customRow":(record) => {
                        return {
                            on: {
                                click: () => _vm.navigateToEvent(String(record.key)), // click row
                            },
                        };
                    }}}):_vm._e()],1)]),_c('a-modal',{attrs:{"ok-text":_vm.$t(_vm.isExistingStatus ? 'Update' : 'Add'),"cancel-text":_vm.$t('Cancel'),"destroyOnClose":"","wrapClassName":"statuses-modal","centered":"","width":"80vw"},on:{"ok":_vm.formatDataForStatusEvent,"cancel":_vm.closePopup},model:{value:(_vm.isNewStatusModalVisible),callback:function ($$v) {_vm.isNewStatusModalVisible=$$v},expression:"isNewStatusModalVisible"}},[_c('add-new-status-event',{attrs:{"isAdmin":_vm.isSuperAdmin},on:{"createStatusEvent":_vm.createStatusEvent,"closePopup":_vm.closePopup}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }