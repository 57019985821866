
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import StatusEventConfiguration from '@/models/interfaces/StatusEventConfiguration';
import ActivityFormConfiguration from '@/models/interfaces/ActivityFormConfiguration';
import Label from '@/models/Label';
import StatusEvent from '@/models/StatusEvent';
import NextStepTemplate from '@/models/NextStepTemplate';
import LabelReporistory from '@/repositories/LabelReporistory';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import ActivityForm from '@/components/views/AutomaticEvents/ActivityForm.vue';
import NextStepTemplateRepository from '@/repositories/NextStepTemplateRepository';
import StatusEventRepository from '@/repositories/StatusEventRepository';
import CMSUser from '@/models/CMSUser';

@Component({
    name: 'AddNewStatusEvent',
    components: {
        ActivityForm,
    },
})
export default class AddNewStatusEvent extends Vue {
    @Prop({ type: Boolean, default: false }) private isAdmin!: boolean;
    private statusEventId: null | string = null;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private statusEvent: StatusEventConfiguration | null = null;
    private nextStepTemplateId: string | null = null;

    // Main object for creating event configuration
    private formData: StatusEventConfiguration = {
        name: null,
        labelName: null,
        labelId: null,
        status: 'ACTIVE',
        statusChange: 'BEFORE',
        type: 'EMAIL',
        conditions: {
            selectedTimeStep: null,
            timeUnit: null,
            email: null,
        },
        receptionUsers: [],
    };

    private activityForm: ActivityFormConfiguration = {
        name: null,
        note: null,
        activityType: null,
        responsiblePersonType: null,
    };

    private emailSendToOptions: string[] = ['CREATOR', 'COMMERCIALIST', 'CLIENT'];
    private statusOptions: string[] = ['ACTIVE', 'ARCHIVED'];
    private statusChange: string[] = ['BEFORE', 'AFTER'];
    private types: string[] = ['EMAIL', 'POPUP'];
    private timeUnitOptions: string[] = ['days', 'hours', 'minutes', 'seconds'];
    private emailAttachmentOptions: string[] = ['lead', 'offer', 'order', 'all'];

    private get allLabels() {
        return LabelReporistory.getAll();
    }

    private get isPopup() {
        return this.formData.type === 'POPUP';
    }

    private get nextStepTemplate() {
        if (!this.nextStepTemplateId) {
            return null;
        }

        return NextStepTemplateRepository.getById(this.nextStepTemplateId);
    }

    private updateActivityForm(data: any) {
        this.activityForm = {
            ...data,
        };
    }

    // Format data and create new/update status event
    private async formatDataForNewStatus(isExistingStatus: boolean) {
        if (!this.isAdmin) {
            this.$notification.error({
                message: this.$t('Error updating event') as string,
                description: this.$t('You do not have the rights to update event statuses') as string,
            });

            return;
        }

        if (!this.checkAreFieldsAreValid()) {
            return;
        }

        if (this.formData.type === 'POPUP' && !this.validateActivityForm()) {
            return;
        }

        // Add status event id to activity form payload
        this.activityForm.statusEventId = this.statusEventId;

        if (isExistingStatus && this.statusEventId) {
            await this.updateStatusEvent(this.statusEventId);
        } else {
            this.$emit('createStatusEvent', this.formData);
        }
    }

    private checkAreFieldsAreValid() {
        if (!this.formData.name) {
            this.$notification.error({
                message: this.$t('Please add a name!'),
                description: '',
            });
            return false;
        }
        if (!this.formData.receptionUsers?.length && this.formData.type === 'EMAIL') {
            this.$notification.error({
                message: this.$t('Please add a user!'),
                description: '',
            });
            return false;
        }
        if (!this.formData?.labelId) {
            this.$notification.error({
                message: this.$t('Please select a label!'),
                description: '',
            });
            return false;
        }
        if (this.formData.conditions?.selectedTimeStep === null) {
            this.$notification.error({
                message: this.$t('Please add interval!'),
                description: '',
            });
            return false;
        }
        if (this.formData.conditions?.selectedTimeStep !== 0 && !this.formData.conditions?.timeUnit) {
            this.$notification.error({
                message: this.$t('Please add time unit!'),
                description: '',
            });
            return false;
        }
        return true;
    }

    // Fetch selected status event and serialize data
    private async fetchNewStatusEvent(id: string) {
        this.loadingOverlay.start();
        this.statusEventId = id;

        try {
            await StatusEvent.getById(id);
            this.statusEvent = StatusEventRepository.getById(id) as StatusEventConfiguration;

            // Fetch next step template after status event is fetched
            if (this.statusEvent.type === 'POPUP' && this.statusEvent.nextStepTemplate) {
                this.nextStepTemplateId = String(this.statusEvent.nextStepTemplate);
                await NextStepTemplate.getById(this.nextStepTemplateId);

                // Update activity form data
                if (this.nextStepTemplate) {
                    this.activityForm = {
                        activityType: this.nextStepTemplate.type.id,
                        name: this.nextStepTemplate.name,
                        note: this.nextStepTemplate.note,
                        responsiblePersonType: this.nextStepTemplate.responsiblePersonType,
                    };
                }
            }
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error while fetching status event!'),
                description: '',
            });
            return;
        } finally {
            this.loadingOverlay.stop();
        }

        if (this.statusEvent) {
            const label = this.allLabels.find(
                (labelItem) => labelItem.name === this.statusEvent?.projectLabel,
            );

            this.formData = {
                name: this.statusEvent.name,
                labelName: this.statusEvent.projectLabel,
                labelId: label ? label.id : null,
                status: this.statusEvent.status,
                statusChange: this.statusEvent.statusChange,
                type: this.statusEvent.type,
                conditions: { ...this.statusEvent.conditions! },
                receptionUsers: this.statusEvent.receptionUsers,
            };
        }
    }

    private async updateStatusEvent(id: string) {
        this.loadingOverlay.start();

        try {
            await StatusEvent.updateExisting(id, this.formData);
            await this.createOrUpdateNextStepTemplate();
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error while updating status event!'),
                description: '',
            });
            return;
        } finally {
            this.loadingOverlay.stop();
        }

        this.$notification.success({
            message: this.$t('Status event updated!'),
            description: '',
        });

        this.$emit('closePopup');
    }

    private async createOrUpdateNextStepTemplate() {
        if (this.formData.type === 'POPUP') {
            if (this.nextStepTemplateId) {
                await NextStepTemplate.updateExisting(this.nextStepTemplateId, this.activityForm);
            } else {
                await NextStepTemplate.createNew(this.activityForm);
            }

            this.$notification.success({
                message: this.$t(`Activity template ${this.nextStepTemplateId ? 'updated' : 'created'}!`),
                description: '',
            });

            return Promise.resolve();
        }
    }

    private getStatusEventIdAndCallNextStepTemplate(statusEventId: string) {
        // Add status event id to activity form payload
        this.activityForm.statusEventId = statusEventId;
        this.createOrUpdateNextStepTemplate();
    }

    private validateActivityForm() {
        if (!this.activityForm.activityType) {
            this.$notification.error({
                message: this.$t('Please add an activity type!'),
                description: '',
            });
            return false;
        }

        if (!this.activityForm.responsiblePersonType) {
            this.$notification.error({
                message: this.$t('Please assign a user to activity!'),
                description: '',
            });
            return false;
        }
        return true;
    }

    private async mounted() {
        await Promise.allSettled([Label.getAll(), CMSUser.getAll()]);
    }

    private created() {
        EventBus.$on(EventBusEvents.formatDataForNewStatus, this.formatDataForNewStatus);
        EventBus.$on(EventBusEvents.fetchNewStatusEvent, this.fetchNewStatusEvent);
        EventBus.$on(EventBusEvents.createNewActivity, this.getStatusEventIdAndCallNextStepTemplate);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.formatDataForNewStatus, this.formatDataForNewStatus);
        EventBus.$off(EventBusEvents.fetchNewStatusEvent, this.fetchNewStatusEvent);
        EventBus.$off(EventBusEvents.createNewActivity, this.getStatusEventIdAndCallNextStepTemplate);
    }
}
