import NextStepTemplate from '@/models/NextStepTemplate';

export default class NextStepTemplateRepository {
    public static getAll() {
        return NextStepTemplate.all();
    }

    public static getById(id: string) {
        return NextStepTemplate.query()
            .whereId(id)
            .withAll()
            .first();
    }
}
